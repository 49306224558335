
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    src: url("../assets/fonts/Inter/Inter-Thin.ttf") format("truetype");
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    src: url("../assets/fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url("../assets/fonts/Inter/Inter-Light.ttf") format("truetype");
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url("../assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url("../assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url("../assets/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url("../assets/fonts/Inter/Inter-Bold.ttf") format("truetype");
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url("../assets/fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url("../assets/fonts/Inter/Inter-Black.ttf") format("truetype");
}

@font-face {
    font-family: 'LoginLogoFont';
    font-style: normal;
    font-weight: 900;
    src: url("../assets/fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'LoginLogoFont';
    font-style: normal;
    font-weight: 500;
    src: url("../assets/fonts/Barlow/Barlow-Medium.ttf") format("truetype");
}
